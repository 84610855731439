import React from 'react';
import Client from '../Client/Client';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import OurWorks from '../OurWorks/OurWorks';
import Services from '../Services/Services';
import Blog from '../Blog/Blog';

import Team from '../Team/Team';
import Pricing from '../Pricing/Pricing';
import WhoWeAre from '../WhoWeAre/WhoWeAre';
import IndustrySection from '../IndustrySection/IndustrySection';
import TestimonialSlider from '../TestimonialSlider/TestimonialSlider';
import GlobalClients from '../GlobalClients/GlobalClients';

const HeaderMain = () => {
    return (
        <>
            
            <Header />
            <Client />
            <WhoWeAre/>
            <IndustrySection/>
            <Services />
            <OurWorks />
            <TestimonialSlider/>
            <GlobalClients/>
            {/* <Team/> */}
            {/* <Pricing/> */}
            <Blog/>
            
        </>
    );
};

export default HeaderMain;
import React from 'react';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { Box, Card, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import Button from '@mui/material/Button';

const Blog = () => {
    const services = [
        {
          title: "The five devices you need to work anytime",
          category: "Techno | Corporate",
          description: "Discover the five essential devices that enable you to work efficiently from anywhere.",
    
          image: "/images/blog1.png", // Image path
        },
        {
          title: "How to learn PHP 10 tips to get you started",
          category: "Techno | Business",
          description: "Kickstart your PHP journey with these 10 essential tips. From mastering the basics to exploring advanced.",
          image: "/images/blog2.png", // Image path
    
        },
        {
          title: "The five devices you need to work anytime",
          category: "Techno | Consulting",
    
          description: "Stay productive on the go with these five essential devices. Discover the tools that enable you to work efficiently anytime.",
          image: "/images/blog3.png", // Image path
    
        },
        
          
    
          
        
      ];
    return (
        <div id='blog'>
            <Box
        sx={{
          minHeight: "100vh",
          width: "100%",
          display: "grid",
          placeItems: "center",
          bgcolor: "background.primary",
          textAlign: "center",
          color: "text.primary",
          borderRadius: 8,
        

        }}
      >
        <Container style={{marginTop:"160px"}}  maxWidth="lg">
          {/* <h1 className="title" style={{textAlign:"left"}} marginBottom={8}>Our Latest Blogs</h1> */}
          <div style={{textAlign:"left"}}>
          
          <h1 className="title">Our Latest Blog</h1>

        <p>
Check out our latest blog for insights into the newest trends and technologies in the IT industry. <br/>
 Stay informed and ahead of the curve with our expert analyses and updates.
          </p>
          </div>
          <Grid  container marginBottom={15} spacing={3} justifyContent="center">
            {services.map((service, index) => (
              <Grid item xs={12} sm={6} md={4}  key={index}>
                <Card style={{borderRadius:"20px", padding:"20px"}}>
                  <CardMedia
                    component="img"
                    height="200"
                    image={service.image}
                    alt={service.title}
                  />
                  <CardContent>
                  <Typography style={{marginTop:"10px", marginBottom:"10px"}} variant="body1">{service.category}</Typography>

                    <Typography style={{marginTop:"12px", marginBottom:"12px"}} variant="h5" gutterBottom>
                      {service.title}
                    </Typography>
                    

                    <Typography variant="body1">{service.description}</Typography>
                    <Button variant="outlined" style={{ color: 'white', borderColor: 'white', marginTop:"30px", borderRadius:"10px"}} endIcon={<TrendingFlatIcon/>}>
      Read More
    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        

      </Box>
        </div>
    );
};

export default Blog;
import React from 'react';
import map from "../../../images/Home/map-bdtask.png"
const GlobalClients = () => {
  return (
    <div className='mt-5'>
   <div className='container'>
   <p>Mohammad IT</p>
  <h3 style={{color:"#4459A8", fontWeight:"bold"}}>Our Global Clients</h3>
  <div className='GlobalClients-image'>
    <img src={map}/>
  </div>
   </div>
    </div>
  );
}

export default GlobalClients;

import React from 'react';
import { Button } from 'react-bootstrap';
import {  FaBootstrap, FaJava, FaJs, FaReact, FaRegArrowAltCircleRight, FaSlidersH, FaUser } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaShoppingCart } from "react-icons/fa";
import { IoLogoCss3, IoLogoHtml5 } from "react-icons/io";
import { MdCheck, MdDynamicForm } from "react-icons/md";
import { DiNodejs, DiResponsive } from "react-icons/di";
import { FaFileWaveform } from "react-icons/fa6";
import { LiaSearchengin } from "react-icons/lia";
import { TbBrandJavascript } from "react-icons/tb";
import { SiFlutter, SiIonic, SiKotlin, SiMysql, SiRedux, SiSwift, SiXamarin, SiXcode } from "react-icons/si";
import { RiTailwindCssFill } from "react-icons/ri";
import { BiLogoPostgresql } from "react-icons/bi";
import { Link } from 'react-router-dom';
const AppDev = () => {

    const plans = [
        {
          title: 'StarterSite',
          price: '$200',
          icon: <FaUsers />,

          features: [
            'Up-to 6 Pages',
            'Free XYZ Domain for 1 Year',
            '1.5 GB Web Hosting for 1 Year Free',
            '100% Mobile Responsive',
            'Basic Slider',
            'SEO Friendly',
            'Cross Browser Compatibility',
            'Admin Dashboard',
            'Design From Pre Build Templates',
            '1 Month Free Maintenance',
            'Free Tech Support',
          ],
          buttonText: 'Choose Plan',
          buttonVariant: '#111430',
        },
        {
          title: 'BusinessPro',
          price: '$280',
          icon: <FaUser />,
          features: [
            'Up-to 15 Pages',
            'Free XYZ Domain for 1 Year',
            '1.5 GB Web Hosting for 1 Year Free',
            '100% Mobile Responsive',
            'Animated Slider',
            'SEO Friendly',
            'Social Media Integration',
            'Cross Browser Compatibility',
            'Online Chat Box',
            'Map Integration',
            'Admin Dashboard',
            'Design From Pre Build Templates',
            '1 Month Free Maintenance',
            'Free Tech Support',
          ],
          buttonText: 'Choose Plan',
          buttonVariant: '#111430',
        },
        {
          title: 'E-Commerce Elite',
          price: '$380',
          icon: <FaShoppingCart  />,

          features: [
            'Up-to 20 Pages',
            'Free com Domain for 1 Year',
            '5 GB Web Hosting for 1 Year Free',
            '100% Mobile Responsive',
            'Animated text/Image Slider',
            'Up-to 30 Products will be Added',
            'Payment Method Integration',
            'Product / Order Management',
            'E-mail Notification',
            'Content Management System',
            'SEO Friendly',
            'Social Media Integration',
            'Cross Browser Compatibility',
            'Fast Page Loading',
            'Admin Dashboard',
          ],
          buttonText: 'Choose Plan',
          buttonVariant: '#111430',
        },
        {
          title: 'E-Commerce Elite',
          price: '$380',
          icon: <FaShoppingCart  />,

          features: [
            'Up-to 20 Pages',
            'Free com Domain for 1 Year',
            '5 GB Web Hosting for 1 Year Free',
            '100% Mobile Responsive',
            'Animated text/Image Slider',
            'Up-to 30 Products will be Added',
            'Payment Method Integration',
            'Product / Order Management',
            'E-mail Notification',
            'Content Management System',
            'SEO Friendly',
            'Social Media Integration',
            'Cross Browser Compatibility',
            'Fast Page Loading',
            'Admin Dashboard',
          ],
          buttonText: 'Choose Plan',
          buttonVariant: '#111430',
        },
        
      ];

    
    return (
        <div >
            {/* Web development header */}
            <section className='web-title mobile-banner'>
                <h1 >We Create Amazing <br/>Mobile Application</h1>
            
                {/* <p>Crafting Your Digital Presence. We bring your ideas to life in the online <br/> (Digital) world.</p> */}
                {/* <button className='border-0 bg-white rounded-fill p-2 web-btn'><h5><Link className='text-underline' to='/'>Back To Home</Link></h5></button> */}
            </section>

            {/* Web development pricing plan */}

            <section className='web-title pricing-tbl'>
                <h1>Choose the Best Plan to Grow <br /> Your Business</h1>

                <div className="pricing-grid">
                    {plans.map((plan, index) => (
                        <div className="pricing-card" key={index}>
                            <div className="pricing-card-body">
                                
                                <div className="pricing-card-subtitle">
                                    <span className='pricing-tbl-icon logo-color'>{plan.icon}</span> <br />
                                    <span className='pricing-tbl-tit'>{plan.title}</span>
                                </div>
                                <div className="pricing-card-price">
                                    <span className='logo-color pricing-tbl-price'>{plan.price}</span>
                                </div>
                                <hr />
                                <ul className="pricing-card-features">
                                    {plan.features.map((feature, i) => (
                                        <li key={i} className="feature-item">
                                            <MdCheck className='text-white me-2 border rounded-circle bg-check' />
                                            <small>{feature}</small>
                                        </li>
                                    ))}
                                </ul>
                                <Button 
                                    style={{ backgroundColor: plan.buttonVariant, borderColor: plan.buttonVariant }} 
                                    className="rounded-pill"
                                >
                                    {plan.buttonText} <FaRegArrowAltCircleRight />
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <section className='frontend'>
            <h1 className='frontend-tit2'>Mobile Application <span className='frontend-tit1'>Technology</span></h1>
                    <p>Designing Digital Delight. We bring your vision to life with engaging, <br/> user-centric interfaces.</p>
           
                    <div className='frontend-grid'>
                    <div className='fontend-tech shadow-md'>
    <div className='mb-4'>
      <FaJava  size={50} style={{color:"#4459A8"}}/> {/* Java */}
      <SiXamarin size={50} style={{color:"#4459A8"}}/> {/* Xamarin */}
      <SiIonic size={50} style={{color:"#4459A8"}}/> {/* Ionic */}
    </div>
    <h4>Java, <br/> Xamarin, <br/> Ionic</h4>
</div>

<div className='fontend-tech shadow-md'>
    <div className='mb-4'>
      <FaReact size={50} style={{color:"#4459A8"}}/> {/* React Native */}
      <SiKotlin size={50} style={{color:"#4459A8"}}/> {/* Kotlin */}
      <SiXcode size={50} style={{color:"#4459A8"}}/> {/* Xcode */}
    </div>
    <h4>React Native, <br/> Kotlin, <br/>  Xcode</h4>
</div>


  <div className='fontend-tech shadow-md'>
    <div className='mb-4'>
      <FaReact size={50} style={{color:"#4459A8"}}/> {/* React Native */}
      <SiFlutter size={50} style={{color:"#4459A8"}}/> {/* Flutter */}
      <SiSwift size={50} style={{color:"#4459A8"}}/> {/* Swift */}
    </div>
    <h4>React Native, <br/> Flutter, <br/>  Swift</h4>
  </div>

  <div className='fontend-tech shadow-md'>
    <div className='mb-4'>
      <DiNodejs size={50} style={{color:"#4459A8"}}/>
      <SiMysql size={50} style={{color:"#4459A8"}}/>
      <BiLogoPostgresql size={50} style={{color:"#4459A8"}}/>
    </div>
    <h4>NodeJs, <br/> MySql <br/>  Postgres</h4>
  </div>


  
</div>

            </section>


            <section class="features">
  <h2>Mohammad IT Mobile Application Features</h2>
  <div class="features-grid">
    <div class="feature-card">
      <div class="feature-icon">
      <MdDynamicForm size={50} />
      </div>
      <h3>Dynamic Website</h3>
      <p>Our reliable and secure Domain & web <br/> hosting services ensure that your website <br/> is always up and running.</p>
    </div>
    <div class="feature-card">
      <div class="feature-icon">
      <FaShoppingCart size={50} />
      </div>
      <h3>E-commerce Solution</h3>
      <p>Our reliable and secure Domain & web <br/> hosting services ensure that your website <br/> is always up and running.</p>

    </div>
    
    <div class="feature-card">
      <div class="feature-icon">
      {/* <FaShoppingCart size={50} /> */}

      <FaFileWaveform />
      </div>
      <h3>Dynamic
      Contact Form</h3>
      <p>Our reliable and secure Domain & web <br/> hosting services ensure that your website <br/> is always up and running.</p>

    </div>
    <div class="feature-card">
      <div class="feature-icon">
     
      <LiaSearchengin size={50} />
      </div>
      <h3>Search Engine Optimization</h3>
      <p>Our reliable and secure Domain & web <br/> hosting services ensure that your website <br/> is always up and running.</p>

    </div>
    <div class="feature-card">
      <div class="feature-icon">
     
      <FaUser size={50}/>
      </div>
      <h3>Customer Support With Smile</h3>
      <p>Our reliable and secure Domain & web <br/> hosting services ensure that your website <br/> is always up and running.</p>

    </div>
   
  </div>
</section>

            
        </div>
    );
};

export default AppDev;
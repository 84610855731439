import React, { useState } from 'react';
import './TestimonialSlider.css'; // Ensure this CSS file includes the necessary styles
import karma from "../../../images/Home/karma.png";
import victor from "../../../images/Home/victor-gbenga-afolabi.png";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const testimonials = [
    {
        image: karma,
        name: "Karma Dorji",
        title: "Advisor eDruk",
        feedback: "Bdtask, Inc. is one of my prime choices and a trustable company for any customized software solutions. They have a strong commitment to delivering their project and maintaining good relationships with their clients. Their Bangladesh office has a great environment with large space."
    },
    {
        image: victor,
        name: "Victor Doe",
        title: "CTO at TechCorp",
        feedback: "Victor has been a key advisor in the technological growth of TechCorp. His insights have helped shape our core solutions and elevate client satisfaction. His work ethic and leadership are unparalleled."
    }
];

const TestimonialSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((currentIndex + 1) % testimonials.length);
    };

    const prevSlide = () => {
        setCurrentIndex((currentIndex - 1 + testimonials.length) % testimonials.length);
    };

    return (
        <div className="testimonial-slider container">
            <h2 className="text-center">Our Customer's <span style={{ color: '#4459A8' }}>Feedback</span></h2>
            <p className="text-center">Our honorable and valuable clients share their values with us. Let’s hear their voices.</p>

            <div className="slides">
                {testimonials.map((testimonial, index) => (
                    <div className={`slide ${index === currentIndex ? 'active' : ''}`} key={index}>
                        <div className="content">
                            <div className="testimonial-image" >
                            <img src={testimonial.image} alt={testimonial.name} />
                            </div>
                            <div className='text-start'>
                            <h6 style={{fontSize:"18px", fontWeight:"600"}}>{testimonial.name}</h6>
                            <p style={{ color: '#4459A8' }}>{testimonial.title}</p>
                            <p >{testimonial.feedback}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="navigation">
                <button className="prev" onClick={prevSlide}>
                    <FaArrowLeft />
                </button>
                <button className="next" onClick={nextSlide}>
                    <FaArrowRight />
                </button>
            </div>
        </div>
    );
};

export default TestimonialSlider;

import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { FaWhatsapp, FaTelegram } from 'react-icons/fa';
import './WhoWeAre.css'; 
import banner from "../../../images/Home/who-we-are.webp"

const WhoWeAre = () => {
  return (
    <section className="about-section py-5">
      <Container>
        <Row className="align-items-center">
          {/* Left Side: Text Section */}
          <Col lg={6} md={12}>
            <div className="text-section">
              <h6 >Who We Are</h6>
              <h1>We are From <span className="fw-bold">Another Space to Automate</span> the World</h1>
              <p>
                Bdtask works in multiple areas including software development,
                website development, customized software solutions, mobile app
                development, theme design, and development. Our dream is to
                continue your journey with success.
              </p>
            

              {/* <button className="whoweare-btn mt-3">More About Us</button> */}
              <button className="btn btn-primary btn-brand">More About Us</button>
              
            </div>
          </Col>

          {/* Right Side: Image Section */}
          <Col lg={6} md={12} className="image-section-wrapper">
            <div className="pos-image">
              {/* <Image
                src="your-main-image.jpg" // replace with the actual path to your main image
                fluid
                className="main-image mb-3"
              />
              <Image
                src="your-small-image.jpg" // replace with the actual path to your small image
                fluid
                className="small-image"
                thumbnail
              /> */}
              <img src={banner}/>
            </div>
          </Col>
        </Row>

        {/* Floating contact icons */}
        {/* <div className="floating-icons">
          <a href="https://wa.me/your-number" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp className="icon whatsapp-icon" />
          </a>
          <a href="https://t.me/your-username" target="_blank" rel="noopener noreferrer">
            <FaTelegram className="icon telegram-icon" />
          </a>
        </div> */}
      </Container>
    </section>
  );
};

export default WhoWeAre;

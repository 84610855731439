import { Link } from 'react-router-dom';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import logo from '../../../images/logo.png';
import './Navbar.css';
import {Navbar, Nav, Container, NavDropdown} from 'react-bootstrap';


const Navbars = () => {

 
    

    return (
        <>
            <Navbar fixed="top" expand="lg" >
    <Container>
        <Navbar.Brand>
            <Link to="/" className="navbar-brand">
                <img src={logo} width={140} alt="" className="img-fluid" />
            </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="" style={{ marginLeft: "auto", marginTop: "10px" }}>

                <Nav.Link as={Link} to='/'>Home</Nav.Link>
                {/* <Nav.Link href="#portfolio">Our Portfolio</Nav.Link> */}
                <NavDropdown
                    title="Services"
                    id="services-dropdown"
                    className="dropdown"
                >
                    <NavDropdown.Item as={Link} to='/web-development'>Web Development</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/app-development">App Development</NavDropdown.Item>
                    {/* <NavDropdown.Item href="#services/templates">Templates</NavDropdown.Item> */}
                </NavDropdown>
                <NavDropdown
                    title="Product"
                    id="services-dropdown"
                    className="dropdown"
                >
                    <NavDropdown.Item as={Link} to='/pos'>POS</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/inventory-management-system">Inventory Management System</NavDropdown.Item>
                    {/* <NavDropdown.Item as={Link} to="/multi-store-ecommerce-cart">Multi-Store eCommerce Cart</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/multi-store-inventory-management">Multi-Store Inventory Management</NavDropdown.Item> */}
                    {/* <NavDropdown.Item href="#services/templates">Templates</NavDropdown.Item> */}
                </NavDropdown>
                <Nav.Link as={Link} to='/team'>Team</Nav.Link>
                <Nav.Link as={Link} to='/blog'>Blog</Nav.Link>
                {/* <Nav.Link href="#pricing">Pricing</Nav.Link> */}
                {/* Services Dropdown with hover effect */}
                
                {/* <Nav.Link href="/about">About Us</Nav.Link> */}
                <Nav.Link as={Link} to="/contact">Contact</Nav.Link>

                {/* <Nav.Link className='btn btn-primary btn-brand' href="/login">Login</Nav.Link> */}
            </Nav>
        </Navbar.Collapse>
    </Container>
</Navbar>
        </>
    );
};

export default Navbars;
import React from 'react';
import './App.css';
import HeaderMain from './components/Home/HeaderMain/HeaderMain';
import { Routes, Route } from 'react-router-dom'; 
import Navbars from './components/Home/Navbar/Navbars';
import Web from './components/Web/Web';
import Footer from './components/Home/Footer/Footer';
import AppDev from './components/AppDev/AppDev';

import Contact from './components/Contact/Contact';
import Blog from './components/Home/Blog/Blog';
import Team from './components/Home/Team/Team';
import Pos from './components/Pos/Pos';
import InventoryManagement from './components/InventoryManagement/InventoryManagement';





function App() {
 
  return (
              <div className='App'>
            <Navbars />
                
                <Routes>

                  <Route path="/" element={<HeaderMain />} />
                  <Route path="/web-development" element={<Web/>} />
                  <Route path="/app-development" element={<AppDev/>} />
                  <Route path="/pos" element={<Pos/>} />
                  <Route path="/inventory-management-system" element={<InventoryManagement/>} />
                  <Route path="/multi-store-ecommerce-cart" element={<InventoryManagement/>} />
                  <Route path="/multi-store-inventory-management" element={<InventoryManagement/>} />
                  <Route path="/blog" element={<Blog/>} />
                  <Route path="/team" element={<Team/>} />
                  <Route path="/contact" element={<Contact/>} />
                  {/* <Route path="/login" element={<Login/>} /> */}


                </Routes>
                <Footer/>
              </div>

        
     
    
  );
}

export default App;

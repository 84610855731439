import { Facebook, LinkedIn, Pinterest, Twitter } from '@mui/icons-material';
import React from 'react';
import muzahid from "../../../images/muzahid.jpg"
import tonmoy from "../../../images/Tonmoy.jpg"
import tanvir from "../../../images/tanvir.jpg"
import najib from "../../../images/NajibPassport1.jpg"
import mohsin from "../../../images/mohsin1.jpg"
import nadia from "../../../images/nadia.jpeg"


const Team = () => {
    return (
        <div style={{marginTop:"160px", marginBottom:"160px"}}>
               <section class="section-whiteh-100 h-100" id='team'>
 
 <div class="container">

     <div class="row ">
    
                 {/* <div class="col-md-12 text-center">

                       <h2 class="section-title"></h2>

                       <p class="section-subtitle">There are many variations of passages of lorem ipsum available but the majority have suffered alternation in some injected humour,</p>
                       
                 </div>  */}

                 <div style={{textAlign:"left"}}>
          
          <h1 className="title">The Team Behind Pacifico</h1>

        <p>
          

            Our team is dedicated to delivering top-notch technology solutions tailored to your business needs.<br/>
             With expertise and innovation, we ensure your digital transformation is seamless and effective.
          </p>
          </div>
          
       
         <div class="col-sm-6 col-md-4">

               <div class="team-item">
               
                   <img src={muzahid} class="team-img" alt="pic" />
                  
                   <h3>Syed Muzahid Islam</h3>
                   
                   <div class="team-info"><p>Manager, Business Development <br/>
                   Team Lead & Operation</p></div>

              
                   <ul class="team-icon">
                   
                       <li><a href="https://x.com/MuzahidulS33462" class="twitter"><Twitter/></a></li>
                       
                       <li><a href="https://www.linkedin.com/in/syedmuzahidul/" class="linkedin">
                        <LinkedIn/>
                     </a></li>
                       <li><a href="https://www.pinterest.com/muazbangladeshltd/" class="pinterest"><Pinterest/></a></li>
                       
                       <li><a href="https://www.facebook.com/muzahidul352/" class="facebook"><Facebook/></a></li>
                       
                       
                     
                       
                   </ul>
                   
               </div>

         </div> 
         <div class="col-sm-6 col-md-4">

               <div class="team-item">
               
                   <img src={nadia} class="team-img" alt="pic" />
                  
                   <h3>Nadia Afrin</h3>
                   
                   <div class="team-info"><p>Public Relationship Officer</p></div>

              
                   <ul class="team-icon">
                   
                       <li><a href="" class="twitter"><Twitter/></a></li>
                       
                       <li><a href="" class="linkedin">
                        <LinkedIn/>
                     </a></li>
                       <li><a href="" class="pinterest"><Pinterest/></a></li>
                       
                       <li><a href="" class="facebook"><Facebook/></a></li>
                       
                       
                     
                       
                   </ul>
                   
               </div>

         </div> 

         <div class="col-sm-6 col-md-4">

               <div class="team-item">
               
                   <img src={tanvir} class="team-img" alt="pic" />
                  
                   <h3>Nahiduzzaman Tanvir</h3>
                   
                   <div class="team-info"><p>Sr. Executive (BDM).</p></div>

              
                   <ul class="team-icon">
                   
                       <li><a href="#" class="twitter"><Twitter/></a></li>
                       
                       <li><a href="https://www.linkedin.com/in/nahiduzzaman-tanvir-4146372b3/" class="linkedin">
                        <LinkedIn/>
                     </a></li>
                       <li><a href="https://www.pinterest.com/nahiduzzamantanvir/nt-tanvir/" class="pinterest"><Pinterest/></a></li>
                       
                       <li><a href="https://www.facebook.com/nahiduzzaman.tanvir.5/" class="facebook"><Facebook/></a></li>
                       
                       
                     
                       
                   </ul>
                   
               </div>

         </div> 

         <div class="col-sm-6 col-md-4">

               <div class="team-item">
               
                   <img src={mohsin} class="team-img" alt="pic" />
                  
                   <h3>Md. Mohsin Kabir</h3>
                   
                   <div class="team-info"><p>Fullstack Developer</p></div>

              
                   <ul class="team-icon">
                   
                       <li><a href="https://x.com/mdmohsinkabir20" class="twitter"><Twitter/></a></li>
                       
                       <li><a href="https://www.linkedin.com/in/mohsinkabircs/" class="linkedin">
                        <LinkedIn/>
                     </a></li>
                       <li><a href="https://www.pinterest.com/mohsinkabircse/" class="pinterest"><Pinterest/></a></li>
                       
                       <li><a href="https://www.facebook.com/mohsinkabircs/" class="facebook"><Facebook/></a></li>
                       
                       
                     
                       
                   </ul>
                   
               </div>

         </div> 

         <div class="col-sm-6 col-md-4">

<div class="team-item">

    <img src={najib} class="team-img" alt="pic" />
   
    <h3>Najib Ahmed</h3>
    
    <div class="team-info"><p>Flutter Developer</p></div>

   
    <ul class="team-icon">
    
        <li><a href="#" class="twitter"><Twitter/></a></li>
        
        <li><a href="#" class="linkedin">
         <LinkedIn/>
      </a></li>
        <li><a href="#" class="pinterest"><Pinterest/></a></li>
        
        <li><a href="#" class="facebook"><Facebook/></a></li>
        
        
      
        
    </ul>
    
</div>

</div> 

         <div class="col-sm-6 col-md-4">

               <div class="team-item">
               
                   <img src={tonmoy} class="team-img" alt="pic" />
                  
                   <h3>Tonmoy Das</h3>
                   
                   <div class="team-info"><p>Digital Marketer</p></div>

              
                   <ul class="team-icon">
                   
                       <li><a href="" class="twitter"><Twitter/></a></li>
                       
                       <li><a href="https://bd.linkedin.com/in/tonmoydas63" class="linkedin">
                        <LinkedIn/>
                     </a></li>
                       <li><a href="" class="pinterest"><Pinterest/></a></li>
                       
                       <li><a href="https://www.facebook.com/profile.php?id=100008274674141&sk=about" class="facebook"><Facebook/></a></li>
                       
                       
                     
                       
                   </ul>
                   
               </div>

         </div> 
      

         
         
        
     </div> 
 
 </div> 

 </section>
        </div>
    );
};

export default Team;
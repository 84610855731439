import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaLinkedin, FaInstagram, FaYoutube, FaPhoneVolume, FaPhoneAlt } from 'react-icons/fa';
import { FaCcPaypal } from "react-icons/fa";
import { MdLocalPhone, MdOutlineMail } from 'react-icons/md';
import './Footer.css'
const Footer = () => {
  return (
    <footer className="bg-light text-dark py-4">
      <Container>
        <Row className="text-center text-md-start">
          {/* Column for Logo and Legal Entity */}
          <Col xs={12} md={4} className="mb-3 mb-md-0">
            <h5>Mohammad IT</h5>
            <p>One-stop web & cloud solutions</p>
            <p><strong>LEGAL ENTITY:</strong></p>
            <p>Mohammad IT – For Bangladesh & all International Markets.</p>
            {/* <p>Brain Code Ltd – For Bangladesh Market.</p> */}
            <div className="d-flex justify-content-center justify-content-md-start">
              <a href="#" className="me-3 text-dark"><FaFacebook size={30} /></a>
              <a href="#" className="me-3 text-dark"><FaLinkedin size={30} /></a>
              <a href="#" className="me-3 text-dark"><FaInstagram size={30} /></a>
              <a href="#" className="me-3 text-dark"><FaYoutube size={30} /></a>
            </div>
          </Col>

          {/* Column for Addresses */}
          <Col xs={12} md={4} className="mb-3 mb-md-0">
            <h5>Address</h5>
            
            <p>58/10, Nurtaj, পান্থপথ, Free School St, Dhaka, Bangladesh.</p>
          </Col>

          {/* Column for Contact Info and Payment Methods */}
          <Col xs={12} md={4}>
            <h5>Contact</h5>
            <p><MdOutlineMail size={22} /> info@mohammadit.com</p>
            <p><MdLocalPhone size={22} /> +880-1518301098 (Whatsapp)</p>

            <img className='payment-info' src='https://api.nurtaj.com/media/643ccb2838adb.webp' alt=''/>
           
           
        
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} md={6}>
            <p className="text-center text-md-start">Copyright © 2024 Mohammad IT. All rights Reserved.</p>
          </Col>
          <Col xs={12} md={6} className="text-center text-md-end">
            <a href="#" className="text-dark text-underline  me-3">Usage Policy</a>
            <a href="#" className="text-dark text-underline me-3">Privacy Policy</a>
            <a href="#" className="text-dark text-underline me-3">Terms of Service</a>
            <a href="#" className="text-dark text-underline">FAQ</a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;

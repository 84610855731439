import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

// ...import other icons as needed

const industries = [
  { title: 'FinTech', icon: require("../../../images/industry-section/automotive.png") },
  { title: 'Healthcare', icon: require("../../../images/industry-section/healthcare.png") },
  { title: 'E-Commerce', icon: require("../../../images/industry-section/ecommerce.png") },
  { title: 'Automotive', icon: require("../../../images/industry-section/automotive.png")  },
  { title: 'Education', icon: require("../../../images/industry-section/education.png")  },
  { title: 'Real Estate', icon: require("../../../images/industry-section/real-estate.png")  },
  { title: 'Hospitality', icon: require("../../../images/industry-section/hospitality.png")  },
  { title: 'RMG', icon: require("../../../images/industry-section/rmg.png")  },
  { title: 'Pharmacy', icon: require("../../../images/industry-section/pharmacy.png")  },
  { title: 'Aviation', icon: require("../../../images/industry-section/aviation.png")  },
  { title: 'UI/UX', icon: require("../../../images/industry-section/ui-ux.png")  },
  { title: 'Many More', icon: require("../../../images/industry-section/more.png")  },
];

const IndustrySection = () => {
  return (
    <section className="industry-section py-5">
      <Container>
        <h2 className="text-center mb-4">Industry <span style={{ color: '#4459A8' }}>We Serve</span></h2>
        <p className="text-center mb-5">Bridge Your Business with the Virtual World</p>
        <Row>
          {industries.map((industry, index) => (
            <Col xs={6} md={4} lg={3} className="mb-4" key={index}>
              <Card className="h-100 text-center shadow-sm border-0">
                <Card.Body>
                  <img src={industry.icon} alt={industry.title} className="mb-3" style={{ width: '50px', height: '50px' }} />
                  <Card.Title>{industry.title}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default IndustrySection;

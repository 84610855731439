import React from 'react';
import {  Row, Col, Card, Button } from 'react-bootstrap';
import banner1 from "../../images/pos/banner1.webp"
import modules from '../../images/pos/pos-mudules.webp'
import crm from '../../images/pos/crm.webp'
import accounts from '../../images/pos/accounts.webp'
import stock from '../../images/pos/stock-management.webp'
import realtime from '../../images/pos/realtime-reports.webp'
import rolePermission from '../../images/pos/role-permission.webp'
import hrm from '../../images/pos/hrm.webp'
import guiPos from '../../images/pos/gui-pos.webp'
import pm from '../../images/pos/product-management.webp'
import { FaRegArrowAltCircleRight, FaShoppingCart, FaUser, FaUsers } from 'react-icons/fa';
import { MdCheck } from 'react-icons/md';



function InventoryManagement() {

  const benefits = [
    { title: "Inventory Monitoring", description: "It has a fully-integrated Inventory Management system. Fully illustrated stock information, purchase information, sale information. You will also get a notification before running out any product.", icon: require('../../images/pos/icon-1.webp') },
    { title: "Make Better Decision", description: "Get a complete Graphical view of your business—from Accounts, Sale, Purchase, Inventory and Customer Service with reliable reports. You can make a better decision about the future of your business.", icon: require('../../images/pos/icon-2.webp') },
    { title: "Remote Access", description: "You can control your business from anywhere in the world with an internet connection. To get remote access you can use our android app or you can directly access your sales ERP software from a browser.", icon: require('../../images/pos/icon-3.webp') },
    { title: "Comfortable Sales System", description: "Sales ERP software has a fully automatic Sales system. There is a POS system for sale. There is also an excellent sales system called GUI POS, which can make the sale faster.", icon: require('../../images/pos/icon-4.webp') },
    { title: "Online and Offline Service", description: "Sales ERP software is an online and offline ERP system. The offline version has the same features as online. All of your data will be stored on your localhost.", icon: require('../../images/pos/icon-5.webp') },
    { title: "Bulk Data Insert", description: "Inserting huge amounts of data such as product info is very time-consuming. To save your time Sales ERP has a CSV(excel) file upload option.", icon: require('../../images/pos/icon-6.webp') },
    { title: "Spectacular Dashboard", description: "You will get a full overview of your business in the dashboard of Sales ERP system software. Not only listed views but also graphical view reports of our sales and inventory. Our sales ERP software dashboard is very fantastic and user-friendly.", icon: require('../../images/pos/icon-7.webp') },
    { title: "Data backup", description: "Sales ERP has a smart Data Backup system, which can allow you to export the database at any time.", icon: require('../../images/pos/icon-8.webp') },
];

  
const plans = [
  {
    title: 'Basic',
    price: '$200',
    icon: <FaUsers />,

    features: [
      'Up-to 6 Pages',
      'Free XYZ Domain for 1 Year',
      '1.5 GB Web Hosting for 1 Year Free',
      '100% Mobile Responsive',
      'Basic Slider',
      'SEO Friendly',
      'Cross Browser Compatibility',
      'Admin Dashboard',
      'Design From Pre Build Templates',
      '1 Month Free Maintenance',
      'Free Tech Support',
    ],
    buttonText: 'Choose Plan',
    buttonVariant: '#111430',
  },
  {
    title: 'Standard',
    price: '$280',
    icon: <FaUser />,
    features: [
      'Up-to 15 Pages',
      'Free XYZ Domain for 1 Year',
      '1.5 GB Web Hosting for 1 Year Free',
      '100% Mobile Responsive',
      'Animated Slider',
      'SEO Friendly',
      'Social Media Integration',
      'Cross Browser Compatibility',
      'Online Chat Box',
      'Map Integration',
      'Admin Dashboard',
      'Design From Pre Build Templates',
      '1 Month Free Maintenance',
      'Free Tech Support',
    ],
    buttonText: 'Choose Plan',
    buttonVariant: '#111430',
  },
  {
    title: 'Premium',
    price: '$380',
    icon: <FaShoppingCart  />,

    features: [
      'Up-to 20 Pages',
      'Free com Domain for 1 Year',
      '5 GB Web Hosting for 1 Year Free',
      '100% Mobile Responsive',
      'Animated text/Image Slider',
      'Up-to 30 Products will be Added',
      'Payment Method Integration',
      'Product / Order Management',
      'E-mail Notification',
      'Content Management System',
      'SEO Friendly',
      'Social Media Integration',
      'Cross Browser Compatibility',
      'Fast Page Loading',
      'Admin Dashboard',
    ],
    buttonText: 'Choose Plan',
    buttonVariant: '#111430',
  },

  
];


const products = [
  {
    title: 'Inventory Management System',
    description: 'Inventory management system is an automation software to track the Business inventory recording, ordering, purchasing etc. Get This inventory Software with inventory control system with very reasonable Price.',
    img: require('../../images/product/inventory-management.jpg'),
  },
  {
    title: 'Multi-Store eCommerce Cart',
    description: 'Isshue is a multi-store eCommerce shopping cart software. It is a complete solution for eCommerce business management. This robust software is an all in one package for website management with a dynamic backend',
    img: require('../../images/product/multi-store-ecommerce.jpg'),

  },
  {
    title: 'Multi-Store Inventory Management',
    description: 'M-Store is a Modern and responsive big Business management system. It is developed by PHP and Codeigniter framework. It is designed and developed for thinking shop, big and small business, company and any types of business',
    img: require('../../images/product/multi-store-inventor-management.jpg.jpg'),

  },
];
 
  return (
              <div className='container'>
         <section className='pos'>
  <div className='pos-info'>
    <h2>Mohammad Inventory Management is the Best Inventory Management Software</h2>
    <p>Mohammad Inventory Managementis a smart ERP solution to manage your business. Mohammad Inventory Management software not only helps you to manage your business but also keeps track of your inventory and human resources. It has a full account management system also. To limit your small & big business, outlet or company Sales ERP is the right choice. BDtask provides you the best ERP software for small businesses.</p>
    <div>
      <button className="btn btn-primary btn-brand">Live Demo</button>
      <button className="btn btn-buy ms-2">Buy Now</button>
    </div>
  </div>
  <div className="pos-image">
    <img src={banner1} alt="POS Software" />
  </div>
</section>


           
<section className="benefits-section">
  <h2 className="text-center mb-4">Benefits Of Mohammad Inventory Management Software</h2>
  <p className="text-center">
    There are a lot of interesting features that will boost your business up. <br/>
    We are the best Mohammad Inventory Management software company. Those features are going to make your work so easy and give you <br/> 
    ultimate control over your business. Maintain your small or local business by using bdtask Mohammad Inventory Management.
  </p>
  
  <Row className="justify-content-center">
    {benefits.map((benefit, index) => (
      <Col xs={12} sm={6} md={4} key={index} className="mb-4">
        <Card className="h-100 text-center shadow-sm">
          <Card.Body>
            <div className="mb-3">
              <img src={benefit.icon} alt={benefit.title} className="benefit-icon" />
            </div>
            <Card.Title>{benefit.title}</Card.Title>
            <Card.Text>{benefit.description}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
    ))}
  </Row>
</section>


           <section className='dashboard'>
          <div>
          <h2 className="text-center mb-4 mt-5">Mohammad Inventory Management Software Modules</h2>
      <p className="text-center">To manage your business sales in the Mohammad Inventory Management system.  Mohammad Inventory Management <br/> 
      automates software to help to the sales and distribution activities with clients, <br/>
      follow-ups and opponent assessment, etc. Some of the sales ERP modules are described here.</p>
          </div>
     
      <div className='pos-modules'>
            <div className='pos-info'>
            <h2>Dynamic Dashboard:</h2>
        <p>  The dashboard is a significant piece of any product. To bring all the vital data about your business at the tip of your finger, we make a remarkable dashboard. You can know about the number of products, customers, suppliers, and sales. The dashboard will show you product info for every most selling product by a histogram. To compare the sale and purchase there is a line chart. Which can give you a total graphical view about the sale and purchase ratio monthly ?</p>
            <div>
            <button className="btn btn-primary btn-brand">Live Demo</button>
        

            </div>
            </div>
            <div className='pos-image'>
              <img src={modules}/>
            </div>
           </div>
           </section>

           <section className='gui-pos'>

           <div className='pos-image'>
              <img src={guiPos}/>
            </div>
            <div className='pos-info'>
            <h2>Mohammad Inventory Management image/slideshow</h2>
            <p>To make your sales effortless Sales ERP has 3 different sales systems.</p>
            <li>New Sale.
              </li>
              <li>
              GUI POS.
              </li>
              <li>
              POS Sale.
              </li>
        <p className='mt-3'>  Sales ERP is a smart Mohammad Inventory Management solution to manage your business. 
        Mohammad Inventory Management software not only helps you to manage your business 
          but also keeps track of your inventory and human resources. 
            It has a full account management system also. To limit your small & big business, 
            outlet or company Mohammad Inventory Management is the right choice. BDtask provides you 
            the best Mohammad Inventory Management software for small businesses.</p>
            <div>
            <button className="btn btn-primary btn-brand">Live Demo</button>
            

            </div>
            </div>
         
           </section>

           <section>
           <div className='product'>
            <div className='pos-info'>
            <h2>Product Management:</h2>
        <p>  The dashboard is a significant piece of any product. To bring all the vital data about your business at the tip of your finger, we make a remarkable dashboard. You can know about the number of products, customers, suppliers, and sales. The dashboard will show you product info for every most selling product by a histogram. To compare the sale and purchase there is a line chart. Which can give you a total graphical view about the sale and purchase ratio monthly ?</p>
            <div>
            <button className="btn btn-primary btn-brand">Live Demo</button>
        

            </div>
            </div>
            <div className='pos-image'>
              <img src={pm}/>
            </div>
           </div>
           </section>

           <section className='crm'>

<div className='pos-image'>
   <img src={crm}/>
 </div>
 <div className='pos-info'>
 <h2>Mohammad Inventory Management system:</h2>
 <p>With the Mohammad Inventory Management module, you can boost your sales. To build up a long-term business CRM can play a vital role. Sales Mohammad Inventory Management software has an enhanced CRM system to communicate with customers and keep track of every single purchase of a customer. These features include the following section:</p>
 <li>Add Customer: you can enter customer info to keep record.
   </li>
   <li>
   Manage and delete data from your customer.
   </li>
   <li>
   Only the credit customer list will show here.
   </li>
   <li>
   The list of the paid customers will show here.
   </li>
   <li>
   You can add advance payment info to a customer.
   </li>

 <div>
 <button className="btn btn-primary btn-brand mt-3">Live Demo</button>
 

 </div>
 </div>

            </section>

           <section className='accounts'>


 <div className='pos-info'>
 <h2>Accounts Management</h2>
 <p>Whole inflow & outflow of money is managed by the accounts module. The Accounts module of Sales Mohammad Inventory Management software can keep track of all accounts-related transactions like account,</p>
 <li>Supplier payment
   </li>
   <li>
   Customer receive
   </li>
   <li>
   Debit & Credit voucher.
   </li>
   <li>
   Journal voucher
   </li>
   <li>
   Voucher approval
   </li>
   <li>
   Cash adjustment.
   </li>
   <li>
   Reports
   </li>
   <li>
   Cash book & bank book
   </li>
   <li>
   General ledger & Inventory ledger
   </li>
   <li>
   Trial balance
   </li>
   <li>
   Profit loss
   </li>

 <div>
 <button className="btn btn-primary btn-brand mt-3">Live Demo</button>
 

 </div>
 </div>

 <div className='pos-image'>
   <img src={accounts}/>
 </div>

            </section>
{/* 
           <section className='stock-management'>
           <div className='pos-image'>
   <img src={stock}/>
 </div>

 <div className='pos-info'>
 <h2>Stock Management</h2>
 <p>Stock modules manage the flow of all product items. To control this flow business ERP software has an outstanding stock management system. You can learn about each of your product items:</p>
  <li>
  Available Quantity.
   </li>
   <li>
   Purchase Quantity.
   </li>
   <li>
   Sale Quantity.
   </li>
   <li>
   Total Stock Price.
   </li>
   <li>
   Reaming Stock Price.
   </li>
   <li>
   Return Option.
   </li>
   
 <div>
 <button className="btn btn-primary btn-brand mt-3">Live Demo</button>
 

 </div>
 </div>

 

            </section> */}

            <section className='stock-management'>
           <div>
   <img src={stock}/>
 </div>

 <div className='pos-info'>
 <h2>Stock Management</h2>
 <p>Stock modules manage the flow of all product items. To control this flow business ERP software has an outstanding stock management system. You can learn about each of your product items:</p>
  <li>
  Available Quantity.
   </li>
   <li>
   Purchase Quantity.
   </li>
   <li>
   Sale Quantity.
   </li>
   <li>
   Total Stock Price.
   </li>
   <li>
   Reaming Stock Price.
   </li>
   <li>
   Return Option.
   </li>
   
 <div>
 <button className="btn btn-primary btn-brand mt-3">Live Demo</button>
 

 </div>
 </div>

 

            </section>

            <section className='realtime '>


 <div className='pos-info'>
 <h2>Real time Reports</h2>
 <p>Whole inflow & outflow of money is managed by the accounts module. The Accounts module of Sales ERP software can keep track of all accounts-related transactions like account,</p>
 <li>Supplier payment
   </li>
   <li>
   Customer receive
   </li>
   <li>
   Debit & Credit voucher.
   </li>
   <li>
   Journal voucher
   </li>
   <li>
   Voucher approval
   </li>
   <li>
   Cash adjustment.
   </li>
   <li>
   Reports
   </li>
   <li>
   Cash book & bank book
   </li>
   <li>
   General ledger & Inventory ledger
   </li>
   <li>
   Trial balance
   </li>
   <li>
   Profit loss
   </li>

   <p className='mt-3'>All the reports have a date filter. This kind of report will help you to make decisions for a specific product or category. The profit report, which is shows invoice wise. So you can easily determine your profit.</p>

 <div>
 <button className="btn btn-primary btn-brand ">Live Demo</button>
 

 </div>
 </div>

 <div className='pos-image'>
   <img src={accounts}/>
 </div>

            </section>

            <section className='role-permission'>
           <div>

            <div className='pos-image'>
            <img  src={stock}/>

            </div>
 </div>

 <div className='pos-info'>
 <h2>
 Role Permission</h2>
 <p>Security is a very important issue for a business. To ensure your security Sales Mohammad Inventory Management has a magnificent Role Permission module. You can limit access of your system for a particular user. Suppose you don’t want to share your accounts or HRM information with your salesman. So you can select some modules he needs for him. After that, the salesman login will only see the selected module. You can make more than one role for use.</p>
 
   
 <div>
 <button className="btn btn-primary btn-brand mt-3">Live Demo</button>
 

 </div>
 </div>

 

            </section>

            <section className='hrm-management '>


<div className='pos-info'>
<h2>Human Resource Management</h2>
<p>The Human Resource Management system will help you in the efficient management of human resources. You can keep track of all of your employees. Business ERP software can manage.</p>
<li>Employee’s salary
  </li>
  <li>
  Benefits
  </li>
  <li>
  Income Tax
  </li>
  <li>
  Expense
  </li>
  <li>
  Attendance etc.
  </li>
  <p className='mt-3'>To control your company expense Sale Mohammad Inventory Management has an excellent option. You can add your regular expense item easily, then you can add an expense in a second.</p>

<div>
<button className="btn btn-primary btn-brand ">Live Demo</button>


</div>
</div>

<div className='pos-image'>
  <img src={accounts}/>
</div>

           </section>

          < section className='web-title pricing-tbl mt-5'>
               

                <div className="pricing-grid">
                    {plans.map((plan, index) => (
                        <div className="pricing-card" key={index}>
                            <div className="pricing-card-body">
                                
                                <div className="pricing-card-subtitle">
                                    <span className='pricing-tbl-icon logo-color'>{plan.icon}</span> <br />
                                    <span className='pricing-tbl-tit'>{plan.title}</span>
                                </div>
                                <div className="pricing-card-price">
                                    <span className='logo-color pricing-tbl-price'>{plan.price}</span>
                                </div>
                                <hr />
                                <ul className="pricing-card-features">
                                    {plan.features.map((feature, i) => (
                                        <li key={i} className="feature-item">
                                            <MdCheck className='text-white me-2 border rounded-circle bg-check' />
                                            <small>{feature}</small>
                                        </li>
                                    ))}
                                </ul>
                                <Button
                                    style={{ backgroundColor: plan.buttonVariant, borderColor: plan.buttonVariant }} 
                                    className="rounded-pill"
                                >
                                    {plan.buttonText} <FaRegArrowAltCircleRight />
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            
            <section className='mt-5 mb-5'>
  <h2 className="text-center mb-4">Our Related Products</h2>
  <p>
    We are selling this Mohammad Inventory Management software, ensuring our beloved customer satisfaction. We have more <br/> 
    than 100 customers right now.
  </p>
  <Row>
    {products.map((product, index) => (
      <Col key={index} md={4}>
        <Card className="product-card">
          <Card.Img variant="top" src={product.img} />
          <Card.Body>
            <Card.Title>{product.title}</Card.Title>
            <Card.Text>{product.description}</Card.Text>
          </Card.Body>
          <div className="card-hover-buttons">
            <button className="btn bg-danger text-white">Buy Now</button>
            <button className="btn btn-primary btn-brand ms-2">Details</button>
          </div>
        </Card>
      </Col>
    ))}
  </Row>
</section>

              </div>

        
     
    
  );
}

export default InventoryManagement;

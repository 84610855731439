import React from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Contact.css'

const Contact = () => {
  return (
    <div>
        <section className='web-title banner'>
                <h1 >Contact Us</h1>
            
                <p>Have a question? Send us a note using the form below and someone <br/> from the Store Republic team will be in touch soon.</p>
                <button className='border-0 bg-white rounded-fill p-2 web-btn'><h5><Link className='text-underline text-black' to='/'>Back To Home</Link></h5></button>


            </section>
    <section> 
        <Container className="pb-5 mt-5 pt-5">
      <Row className="g-4">
        <Col md={4}>
          <Card className="h-100 shadow-sm">
            <Card.Body>
              <div className="text-center mb-3">
                <FaMapMarkerAlt size={40} color="#007bff" />
              </div>
              <Card.Title className="text-center">Our Location:</Card.Title>
              <Card.Text className="text-center">
              58/10, Nurtaj, পান্থপথ, Free School St, Dhaka, Bangladesh.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="h-100 shadow-sm">
            <Card.Body>
              <div className="text-center mb-3">
                <FaPhoneAlt size={40} color="#007bff" />
              </div>
              <Card.Title className="text-center">Phone Number:</Card.Title>
              <Card.Text className="text-center">
              
                +880-1518-301098<br />
                +880-1624-021826
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="h-100 shadow-sm">
            <Card.Body>
              <div className="text-center mb-3">
                <FaEnvelope size={40} color="#007bff" />
              </div>
              <Card.Title className="text-center">Email us at:</Card.Title>
              <Card.Text className="text-center">
                contact@storerepublic.com
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </section>

    <section>
  <Container>
    <div className="contact-form">
      <h1 className="mb-4">Contact us</h1>
      <Form>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formName">
              <Form.Label className="text-start">Your Name</Form.Label>
              <Form.Control className="p-2 rounded" type="text" placeholder="Your Name" />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formEmail">
              <Form.Label className="text-start">E-Mail</Form.Label>
              <Form.Control className="p-2 rounded" type="email" placeholder="Your E-mail" />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formService">
              <Form.Label className="text-start">Services</Form.Label>
              <Form.Control className="p-2 rounded" as="select">
                <option>Select</option>
                <option>Technical Support</option>
                <option>Web Development</option>
                <option>App Development</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formPhone">
              <Form.Label className="text-start">Your Phone</Form.Label>
              <Form.Control className="p-2 rounded" type="text" placeholder="+ 972 53 900 90 90" />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formMessage" className="mb-3">
          <Form.Label className="text-start">Message</Form.Label>
          <Form.Control className="rounded" as="textarea" rows={6} placeholder="Your Text" />
        </Form.Group>
        <div className="text-center">
          <Button variant="primary" type="submit" className="px-5 btn-brand">
            SEND MESSAGE
          </Button>
        </div>
      </Form>
    </div>
  </Container>
</section>


<section>
<div style={{ width: '100%', height: '400px', overflow: 'hidden' }}>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.929714113454!2d90.38685567589707!3d23.74988568879511!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b900618d35f3%3A0xa8f61038cc6f99a8!2sNurtaj%20Bangladesh%20LTD!5e0!3m2!1sen!2sbd!4v1727079895877!5m2!1sen!2sbd"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Google Map"
            />

        </div>
</section>
    </div>
  );
}

export default Contact;
